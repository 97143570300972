import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/css/style.css';
import { graphql, Link } from 'gatsby'

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import Select from 'react-select';

const levelOptions = [
  { value: 'all', label: 'All' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];

const topicOptions = [
  { value: 'all', label: 'All' },
  { value: 'christianLiving', label: 'Christian Living' },
  { value: 'culture', label: 'Culture' },
  { value: 'currentAffairs', label: 'Current Affairs' },
  { value: 'deathAndSuffering', label: 'Death and Suffering' },
  { value: 'health', label: 'Health' },
  { value: 'identity', label: 'Identity' },
  { value: 'ministryAndChurch', label: 'Ministry and Church' },
  { value: 'possessions', label: 'Possessions' },
  { value: 'relationships', label: 'Relationships' },
  { value: 'smallTalk', label: 'Small Talk' },
  { value: 'sins', label: 'Sins' },
  { value: 'theology', label: 'Theology' },
  { value: 'time', label: 'Time' },
  { value: 'workAndEducation', label: 'Work and Education' },
  { value: 'worldviews', label: 'Worldviews' }
]

class QuestionCard extends React.Component {
  render () {
    return (
      <div className="question-card">
        <div className="question-card-body"><p>{this.props.question.Name}</p></div>
        {this.props.question.Level && (
          <div className="question-card-footer">
            <p className="level">LEVEL {this.props.question.Level}</p>
          </div>
        )}
      </div>
    )
  }
}

class QuestionGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allQuestions: props.questions,
      displayQuestions: true,
      gameStarted: false,
      questionHistory: [],  // this stores a history of question indexes
      questionHistoryIndex: -1,
      selectedLevel: { value: 'all', label: 'All' },
      selectedTopic: { value: 'all', label: 'All' }
    };

    this.getNextQuestion = this.getNextQuestion.bind(this);
    this.getPrevQuestion = this.getPrevQuestion.bind(this);
  }

  componentDidMount() {
    this.shuffleQuestions();
  };

  async shuffleQuestions() {
    const allQuestions = [...this.state.allQuestions];
    for (let i = allQuestions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [allQuestions[i], allQuestions[j]] = [allQuestions[j], allQuestions[i]];
    }
    this.setState({allQuestions});
  }

  getNextQuestion() {
    const {
      allQuestions,
      gameStarted,
      questionHistory,
      questionHistoryIndex,
      selectedLevel,
      selectedTopic
    } = this.state;

    // if there are questions to get in the history
    if (questionHistory.length > 0 && questionHistoryIndex < questionHistory.length-1) {
      this.setState((state) => ({
        questionHistoryIndex: state.questionHistoryIndex+1
      }));
    } else { // else, go to the next random question
      let currentQuestionIndex = gameStarted ? questionHistory[questionHistoryIndex] : -1;
      let foundQuestion = false;

      for (let i = currentQuestionIndex+1; i < allQuestions.length; i++) {
        let currentQuestion = allQuestions[i];
        if (
          (selectedLevel.value === "all" || currentQuestion.Level === selectedLevel.value)
          && (selectedTopic.value === "all" || currentQuestion.Tags.includes(selectedTopic.label) )
        ) {
          foundQuestion = true;
          this.setState((state) => ({
            questionHistory: [...state.questionHistory, i],
            questionHistoryIndex: state.questionHistoryIndex+1
          }));
          break;
        }
      }

      if (!foundQuestion) {
        this.setState({displayQuestions: false});
      }

      if (!gameStarted) {
        this.setState({gameStarted: true});
      }
    }
  }

  getPrevQuestion() {
    const {displayQuestions, questionHistoryIndex} = this.state;

    if (questionHistoryIndex > 0 && displayQuestions === true) {
      this.setState({
        questionHistoryIndex: questionHistoryIndex-1
      });
    } else if (displayQuestions === false) {
      this.setState({displayQuestions: true});
    }
  };

  async refreshQuestions() {
    await this.shuffleQuestions();
    this.setState({
      questionHistory: [],
      questionHistoryIndex: -1,
      displayQuestions: true,
      gameStarted: false
    }, this.getNextQuestion);
  };

  handleLevelChange = (selectedLevel) => {
    this.setState((state) => ({
      selectedLevel
    }), this.refreshQuestions);
  };

  handleTopicChange = (selectedTopic) => {
    this.setState((state) => ({
      selectedTopic
    }), this.refreshQuestions);
  };

  render() {
    return (
      <>
      <div className="col col-md-6 mx-auto">
        <div className="row">
          <div className="col">
            <h6>Level of Depth:</h6>
            <Select
              value={this.state.selectedLevel}
              placeholder="Choose a level..."
              options={levelOptions}
              onChange={(selectedLevel) => { this.handleLevelChange(selectedLevel) }}
              label="Select a level..."
            />
          </div>
          <div className="col">
            <h6>Topic:</h6>
            <Select
              value={this.state.selectedTopic}
              placeholder="Choose a topic..."
              options={topicOptions}
              onChange={(selectedTopic) => { this.handleTopicChange(selectedTopic) }}
              label="Select a topic..."
            />
          </div>
        </div>
      </div>
        <div className="my-4 my-md-5 justify-content-center">
          {this.state.displayQuestions
            ? (
              <>
                <Button className="question-button" onClick={this.getNextQuestion}>
                  {this.state.gameStarted && this.state.questionHistoryIndex >= 0
                    ? <QuestionCard question={this.state.allQuestions[this.state.questionHistory[this.state.questionHistoryIndex]]}/>
                    : 'Press to start'
                  }
                </Button>
                {this.state.gameStarted && this.state.questionHistoryIndex >= 0
                  && <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip>Copy question to clipboard</Tooltip>
                      }
                    >
                      <h4><FaRegCopy className="copy-icon mt-3" onClick={() => {navigator.clipboard.writeText(this.state.allQuestions[this.state.questionHistory[this.state.questionHistoryIndex]].Name)}}/></h4>
                    </OverlayTrigger>
                }
              </>
            )
            : <p>No questions to display. Please refresh or choose a different level or topic :)</p>
          }
        </div>
        <div>
          {this.state.gameStarted && this.state.questionHistoryIndex > 0 
            && (
              <Button className="btn-secondary mx-1" onClick={this.getPrevQuestion} disabled={this.state.questionHistoryIndex < 1}>
                Back
              </Button>
            )
          }
          {this.state.gameStarted && this.state.displayQuestions
            && (
              <Button className="btn-secondary mx-1" onClick={this.getNextQuestion} disabled={!this.state.displayQuestions}>
                Next
              </Button>
            )
          }
        </div>
      </>
    );
  }
}

const IndexPage = ({data}) => {
  return (
    <main>
      <div className="content container-sm">
        <title>Intreague</title>
        <h1>
          Welcome to Intreague
        </h1>
        <br />
        <QuestionGenerator questions={data.allQuestionsCsv.nodes} />
        <br />
        <p className="page-footer"><Link to="/questions">See all questions</Link> | <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdlFWSXH0hpMUiHYeRgpd3arRBq6F-eiX59nMZ4WWl7n4XB1A/viewform?usp=sf_link" target="_blank">Submit a question</Link></p>
      </div>
    </main>
  )
}

export const query = graphql`
  query AllQuestionQuery {
    allQuestionsCsv{
      nodes {
        Level
        Name
        Tags
      }
    }
  }
`

export default IndexPage;
